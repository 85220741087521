import { createStitches } from '@stitches/react';

export const { css, getCssText, globalCss, styled, theme } = createStitches({
  theme: {
    colors: {
      blue4: '#E1EBFF',
      blue9: '#0062FF',
      blue11: '#1C4DC1',
      blue12: '#051E57',
      gray1: '#FFFFFF',
      gray2: '#F8F9FD',
      gray3: '#F2F5FC',
      gray4: '#EDF2FA',
      gray5: '#E4EAF7',
      gray6: '#D8E0EE',
      gray7: '#CAD5E7',
      gray8: '#BCC7DC',
      gray9: '#7D8AA1',
      gray11: '#5D6A81',
      green3: '#E4FAE7',
      green4: '#D7F6DB',
      green9: '#0BAF3F',
      green10: '#089738',
      green11: '#1E5431',
      green12: '#06280F',
      red3: '#FFEAEE',
      red4: '#FFE0E6',
      red9: '#CC244B',
      red10: '#B52749',
      red11: '#8C2F45',
      red12: '#2D0C16',
      white: '#FFFFFF',
      yellow9: '#FFCB00',
      yellow10: '#F7B900',
    },
    fonts: {
      sansSerif: '"IBM Plex Sans", "Segoe UI", Tahoma, sans-serif',
      serif: '"IBM Plex Serif", serif',
    },
    fontWeights: {
      medium: '500',
      semiBold: '600',
      bold: '700',
    },
  },
});

export const addGlobalCss = globalCss({
  '*': {
    boxSizing: 'border-box',
  },
  html: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  body: {
    fontFamily: theme.fonts.sansSerif,
    margin: 0,
  },
  img: {
    display: 'block',
  },
});

const BASE_FONT_SIZE = 16;

export type Scale =
  | 1
  | 2
  | 4
  | 6
  | 7
  | 8
  | 8.75
  | 10
  | 12
  | 12.25
  | 14
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 24
  | 29
  | 30
  | 31
  | 32
  | 36
  | 40
  | 45
  | 48
  | 56
  | 64
  | 70
  | 88
  | 175
  | 232
  | 448
  | 512
  | 650
  | 776
  | 1056;

export function rem(scale: Scale): `${number}rem` {
  return `${scale / BASE_FONT_SIZE}rem`;
}
